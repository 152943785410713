.mobile-app--2STeW {
  color: hsla(0, 0%, 15%, 1); }
  .mobile-app__container--1PdRd {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid hsla(0, 0%, 93%, 1);
    border-radius: 6px; }
  .mobile-app__text-container---bTpe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
    @media (max-width: 989px) {
      .mobile-app__text-container---bTpe {
        padding: 30px 20px; } }
  .mobile-app__store-buttons--2Ugqy {
    display: flex; }
    .mobile-app__store-buttons--2Ugqy :first-child {
      margin-right: 15px; }
